import { Box, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { staticSeoTexts } from "../../../data/staticSeoTexts";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { mapWpContent } from "../../../helpers/general/parseWpHtmlToReact/htmlTagsToReactComponents";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import { DatenschutzTemplateProps } from "./DatenschutzTemplateInterfaces";
import { useDatenschutzTemplateStyles } from "./DatenschutzTemplateStyles";

export const DatenschutzTemplate = (
  props: DatenschutzTemplateProps
): JSX.Element => {
  const { classes, cx } = useDatenschutzTemplateStyles();

  const data = useStaticQuery<GatsbyTypes.DatenschutzDataQuery>(graphql`
    query DatenschutzData {
      datenschutz: allWpPage(filter: { id: { eq: "cG9zdDozNzI=" } }) {
        nodes {
          content
        }
      }
    }
  `);

  const datenschutzContent = data.datenschutz.nodes[0].content;

  return (
    <Box className={cx(classes.wrapper, props.className)}>
      <SeoBasics {...staticSeoTexts.datenschutz} />

      <Typography variant="h1">Datenschutz</Typography>

      <Typography variant="body">
        {replaceHtmlWithFunctionComponent(datenschutzContent, mapWpContent)}
      </Typography>
    </Box>
  );
};
