import { Container } from "@mui/material";
import { DatenschutzTemplate } from "../04-templates/DatenschutzTemplate/DatenschutzTemplate";

const DatenschutzPage = (): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <DatenschutzTemplate />
    </Container>
  );
};

export default DatenschutzPage;
