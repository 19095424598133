import { makeStyles } from "tss-react/mui";

export const useDatenschutzTemplateStyles = makeStyles({
  name: "DatenschutzTemplate",
})((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h2,h3": {
      textAlign: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      textAlign: "center",
      wordBreak: "break-word",
    },
  },
}));
